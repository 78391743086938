import { Component, OnInit, Input, Output, EventEmitter, OnDestroy } from '@angular/core';
import { Location } from '@angular/common';
import { Customer } from 'src/app/buisness-object/customer/Customer';
import { Thread } from 'src/app/buisness-object/thread/Thread';
import { User } from 'src/app/buisness-object/user/User';
import { CloneObject } from 'src/app/helper/CloneObject';
import { ENUMTABCUSTOMER } from 'src/app/helper/Enums';
import { DialogService } from 'src/app/service/dialog/dialog.service';
import { ThreadService } from 'src/app/service/thread/thread.service';
import { Message } from 'src/app/buisness-object/thread/Message';
import { Section } from 'src/app/buisness-object/project/Section';
import { Project } from 'src/app/buisness-object/project/Project';
import { catchError, forkJoin, Observable, of, Subscription, tap } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import { BaseComponent } from '../../BaseComponent';

@Component({
  selector: 'app-customer-details',
  templateUrl: './customer-details.component.html',
  styleUrls: ['./customer-details.component.scss']
})
export class CustomerDetailsComponent extends BaseComponent implements OnInit, OnDestroy {
  @Input() selectedCustomer: Customer;
  @Input() users: User[] = [];
  @Input() notificationTickets: Thread[] = [];
  public currentUser: User;
  public threadsFiltered: Thread[] = [];
  public selectedThread: Thread;
  public showCreateTicket = false;
  public tab = ENUMTABCUSTOMER.INFORMATION;
  public amountOpenThreads = 0;
  public showLastAppointments = false;
  public minimizeSidebar = false;
  public routeSubscription: Subscription;
  public displayedTicketsCount: number;
  public defaultFilter: any;
  public filteredOpenThreads: any;
  public amountOpenProjectTickets;
  @Output() editEmitter = new EventEmitter<Customer>();
  @Output() createProjectEmitter = new EventEmitter<undefined>();
  @Output() selectionProjectEmitter = new EventEmitter<Project>();
  @Output() showLastAppointmentsEmitter = new EventEmitter<undefined>();

  constructor(
    private dialogService: DialogService,
    private threadService: ThreadService,
    private locationRoute: Location,
    private activateRoute: ActivatedRoute
  ) { 
    super();
  }

  ngOnInit(): void {
    this.threadService.getThreads(1,this.selectedCustomer.customer_id).subscribe((threads: Thread[]) => {
      if(threads){
        this.selectedCustomer.threads = threads;
        this.defaultFilter = this.threadService.getDefaultFilter();
        this.prepareThreads();
        this.setRouteSubscriptions();
        this.requestProjectTickets().subscribe((result) => {
          console.log(result);
        });
      }
    });
  }

  requestProjectTickets(): Observable<any> {
    const projectIds = [];
    for(let p of this.selectedCustomer.projects){
      projectIds.push(p.project_id);
    }
    const ticketRequests = projectIds.map((id: any) => this.threadService.getThreads(2, id));
    return forkJoin(ticketRequests).pipe(
      tap((result) => {
        if(result){
          for(let p of projectIds){
            for(let threadPackage of result){
              if(threadPackage[0] && threadPackage[0].project_id == p){
                for(let customerProject of this.selectedCustomer.projects){
                  if(customerProject.project_id == p){
                    customerProject.threads = threadPackage;
                    customerProject.getAmountOfOpenUserTickets();
                    break;
                  }
                }
              }
            }
          }
        } else {
          return null;
        }
      }),catchError((error) => {
        console.log(error);
        return of(null);
      })
    );
  }

  ngOnDestroy(): void {
    if(this.routeSubscription) this.routeSubscription.unsubscribe();
  }

  setRouteSubscriptions() {
    this.routeSubscription = this.activateRoute.queryParams.subscribe((params) => {
      if(params && Number(params['projectId'])){
        this.selectionProjectEmitter.emit(this.selectedCustomer.projects.find(p => p.project_id == Number(params['projectId'])));
      } else if(params && Number(params['ticketId'])){
        for(let thread of this.selectedCustomer.threads){
          if(thread.threadId == Number(params['ticketId'])){
            this.selectionThread(thread);
            break;
          }
        }
      } else if(params && Number(params['customerId'])){
        this.selectedThread = null;
        this.searchTicketsAction('');
        this.showCreateTicket = false;
      }
    })
  }

  countDisplayedTickets(count: number) {
    this.displayedTicketsCount = count;
  }

  openViewInfo() {
    this.selectedThread = null;
    this.showCreateTicket = false;
    this.tab = ENUMTABCUSTOMER.INFORMATION;
  }
  openViewTickets() {
    let el = document.getElementById('input_search_tickets') as HTMLInputElement;
    if(el) el.value = "";
    this.selectedThread = null;
    this.tab = ENUMTABCUSTOMER.TICKETS;
  }
  openViewDocu() {
    this.selectedThread = null;
    this.showCreateTicket = false;
    this.tab = ENUMTABCUSTOMER.DOCUMENTATION;
  }

  prepareThreads() {
    this.amountOpenThreads = 0;
    if(this.selectedCustomer.threads && this.selectedCustomer.threads.length > 0){
      for(let ticket of this.selectedCustomer!.threads){
        if(!ticket.resolved) this.amountOpenThreads++;
        for(let user of this.users){
          if(ticket.userId == user.userId) ticket.user = user;
          if(ticket.resolved_by_user_id == user.userId) ticket.resolved_by_user = user;
          for(let msg of ticket.messages){
            if(msg.userId == user.userId){
              msg.user = user;
            }
          }
          if(this.selectedCustomer.seller_id == user.userId) this.selectedCustomer.seller = user;
          if(user.userId == Number(localStorage.getItem('user_id'))) this.currentUser = user;
        }
      }
      for(let t of this.selectedCustomer.threads){
        this.threadsFiltered.push(CloneObject.deepCopy(t))
      }
    }
    this.sortThreads();
  }

  /*getAmountOpenTicketsProject(project: Project) {
    let openTicketsCountProject = 0;
    if(project.threads && project.threads.length > 0){
      for(let ticket of project!.threads){
        ticket.project_id = project.project_id;
        if(!ticket.resolved) openTicketsCountProject++;
      }
    }
    return openTicketsCountProject;
  }*/

  sortThreads() {
    this.threadsFiltered.sort((a, b) => {
      if(a.resolved && b.resolved) return a.createdDate > b.createdDate ? -1 : 1;
      else if(a.resolved && !b.resolved) return 1;
      else if(!a.resolved && b.resolved) return -1;
      else if(!a.resolved && !b.resolved) return a.createdDate > b.createdDate ? -1 : 1;
      return 0;
    })
  }

  selectionOpenCreateTicket() {
    this.selectedThread = null;
    this.tab = ENUMTABCUSTOMER.TICKETS;
    this.showCreateTicket = true;
  }

  successResolveTicket(thread: Thread) {
    let index = this.selectedCustomer.threads.findIndex(t => t.threadId == thread.threadId);
    if(index != -1) this.selectedCustomer.threads[index].resolved = thread.resolved;
    let index2 = this.threadsFiltered.findIndex(t => t.threadId == thread.threadId);
    if(index2 != -1) this.threadsFiltered[index2].resolved = thread.resolved;
    this.amountOpenThreads--;
    this.selectedThread = null;
    //this.routeHandler();
    this.sortThreads();
    this.dialogService.showNotification({
      title: this.dialogService.getTitle(),
      message: 'Ticket gelöst.',
      success: true
    });
  }
  successRestoreTicket(thread: Thread) {
    let index = this.selectedCustomer.threads.findIndex(t => t.threadId == thread.threadId);
    if(index != -1) this.selectedCustomer.threads[index].resolved = thread.resolved;
    let index2 = this.threadsFiltered.findIndex(t => t.threadId == thread.threadId);
    if(index2 != -1) this.threadsFiltered[index2].resolved = thread.resolved;
    this.amountOpenThreads++;
    this.sortThreads();
    this.dialogService.showNotification({
      title: this.dialogService.getPfui(),
      message: 'Ticket wiederhergestellt.',
      success: true
    });
  }

  successCreateTicket(thread: Thread) {
    this.selectedCustomer.threads.push(thread);
    this.threadsFiltered.push(thread);
    this.sortThreads();
    this.amountOpenThreads++;
    this.showCreateTicket = false;
    this.dialogService.showNotification({
      title: 'Erfolgreich',
      message: 'Ticket erstellt.',
      success: true
    });
  }

  successCreateMessageEmitter(thread: Thread) {
    let index = this.selectedCustomer.threads.findIndex(t => t.threadId == thread.threadId);
    if(index != -1) this.selectedCustomer.threads[index] = thread;
    let index2 = this.threadsFiltered.findIndex(t => t.threadId == thread.threadId);
    if(index2 != -1)  this.threadsFiltered[index2] = thread;
    this.sortThreads();
  }

  selectionThread(thread: Thread) {
    if(this.isNotifyTicket(thread)){
      this.removeTicketNotification(thread);
    }
    this.selectedThread = thread;
    this.showCreateTicket = false;
    this.tab = ENUMTABCUSTOMER.TICKETS;
    this.locationRoute.replaceState('/customers?customerId=' + this.selectedCustomer.customer_id + '&ticketId=' + thread.threadId);
  }

  isNotifyTicket(thread: Thread): boolean {
    for(let t of this.notificationTickets){
      if(thread.threadId == t.threadId) return true;
    }
    return false;
  }

  removeTicketNotification(thread: Thread) {
    thread.notifyOpen = false;
    this.threadService.updateThread(thread).subscribe((result) => {
      if(result){
        let index = this.selectedCustomer.threads.findIndex(t => t.threadId == result.threadId);
        if(index > -1) this.selectedCustomer.threads[index].notifyOpen = false;
        this.threadService.changeNotificationTickets(thread);
      }
    })
  }

  selectionCloseThread() {
    this.searchTicketsAction('');
    //this.threadsFiltered = this.selectedCustomer.threads;
    //this.locationRoute.replaceState('/customers?customerId='+this.selectedCustomer.customer_id);
    this.selectedThread = null;
    this.showCreateTicket = false;
  }

  searchTicketsAction(value: string) {
    this.threadsFiltered = this.selectedCustomer.threads.filter((thread) => {
      if(value && value.length > 2){
        this.threadsFiltered = [];
        return thread.user.firstName.toLowerCase().includes(value.toLowerCase()) ||
               thread.user.lastName.toLowerCase().includes(value.toLowerCase()) ||
               (thread.user.firstName + ' ' + thread.user.lastName).toLowerCase().includes(value.toLowerCase()) ||
               thread.threadId.toString().toLowerCase().includes(value.toLowerCase()) ||
               this.messagesIncludeSearch(value, thread.messages) ||
               thread.subject.toLowerCase().includes(value.toLowerCase());;
      } else {
        return this.threadsFiltered;
      }
    });
    this.sortThreads()
  }

  messagesIncludeSearch(value: string, messages: Message[]): boolean {
    for(let msg of messages){
      if(msg.message.toLowerCase().includes(value.toLowerCase())) return true;
    }
    return false;
  }

  showSectionsCustomer() : boolean {
    for(let section of this.selectedCustomer.sections){
      for(let row of section.section_rows){
        if(row.data_value != null) return true;
      }
    }
    return false;
  }

  showSingleSectionCustomer(section: Section) : boolean {
    for(let row of section.section_rows){
      if(row.data_value != null) return true;
    }
    return false;
  }

  setSectionsCustomerBorder(index: number): boolean {
    if(this.selectedCustomer.sections[index] && this.selectedCustomer.sections[index].section_rows.findIndex(r => r.data_value != null) > -1) return false;
    else return true;
  }
}
