<div class="dialog_view">
    <div class="dialog_window">
        <button class="dialog_window_btn_close" (click)="close()"><img src="../../../../assets/negativewhite.svg"></button>
        <div>
            <div class="dialog_window_header">
                <h2>Ticket verschieben</h2>
            </div>
            <div class="dialog_window_body">
                <form>
                  <label id="ticket_typ_lbl">Kunde</label>
                  <select name="" id=""></select>
                  <label id="status_lbl">Projekt</label>
                  <select name="" id=""></select>
                </form>
              </div>
        </div>
        <button class="dialog_window_btn_submit">Verschieben</button>
    </div>
</div>
  