import { Component, EventEmitter, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-dialog-move-ticket',
  templateUrl: './dialog-move-ticket.component.html',
  styleUrls: ['./dialog-move-ticket.component.scss']
})
export class DialogMoveTicketComponent implements OnInit {
  @Output() closeTicketMoveEmitter = new EventEmitter<any>();

  constructor() { }

  ngOnInit(): void {
  }

  close() {
    this.closeTicketMoveEmitter.emit();
  }

}
